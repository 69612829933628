import React, { useEffect } from 'react'
import './App.scss'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

function App() {
    const firebaseConfig = {
        apiKey: 'AIzaSyBveuM_CCEf7a0G31e3Hk4fSxFwb4wpHAk',
        authDomain: 'my-web-324808.firebaseapp.com',
        projectId: 'my-web-324808',
        storageBucket: 'my-web-324808.appspot.com',
        messagingSenderId: '590094200575',
        appId: '1:590094200575:web:e076787f014aa2bdc6e6ca',
        measurementId: 'G-D6F75WW6XL',
    }

    const app = initializeApp(firebaseConfig)
    // eslint-disable-next-line
    const analytics = getAnalytics(app)

    useEffect(() => {
        const woody = document.getElementById('woody')
        const lai = document.getElementById('lai')
        const body = document.body
        woody?.classList.add('woody')
        lai?.classList.add('lai')
        body?.classList.add('background_fade')
    })

    return (
        <>
            <div className="menu">
                <div className="menuItem">
                    <a href={require('./resume/CV.pdf')} target="_blank">
                        Resume
                    </a>
                </div>
            </div>
            <div className="text">
                <div id="woody">Woody</div>
                <div id="lai">Lai</div>
            </div>
            <div className="contact">
                <a href="mailto:woodylai@connect.hku.hk">Contact Me</a>
            </div>
        </>
    )
}

export default App
